body { background-color: #ffffff; }
body { color: var(--clr-17400); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
font-family: 'Bai Jamjuree';
font-weight: 500;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 25px;

@media #{$medium-up} {
font-size: 33px;

}
@media #{$large-up} {
font-size: 32px;

}
}
h2 {
font-family: 'Bai Jamjuree';
font-weight: 500;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 22px;

@media #{$medium-up} {
font-size: 30px;

}
}
h3 {
font-family: 'Bai Jamjuree';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 26px;

}
}
h4 {
font-family: 'Bai Jamjuree';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 22px;

}
}
h5 {
font-family: 'Bai Jamjuree';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 18px;

}
h6 {
font-family: 'Noto Sans';
font-weight: 700;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 30px;

@media #{$large-up} {
font-size: 50px;

}
}
.button {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Arapey';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 22px;

}
}
.me-Quote .quote-author {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
ol,ul {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

}
summary {
font-family: 'Noto Sans';
font-weight: 500;
font-style: normal;
line-height: 1.2;
letter-spacing: 0;
font-size: 18px;

}
nav.mainmenu > .menu-item > a {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
letter-spacing: 0;
font-size: 20px;

}
body {
font-family: 'Noto Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
p { padding-bottom: 1em; }
a {color: var(--clr-17401);}
a:hover {color: var(--clr-17402);}
/* Primary:2 */
.MES2 {
background-color: var(--clr-17400);
color: #ffffff;
 }
/* Primary:3 */
.MES3 {
background-color: var(--clr-17400);
color: #ffffff;
 }
.MES3 {
background-color: var(--clr-17400);
color: #ffffff;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
cite.MEC3{
color: #ffffff;
}
/* Secondary:4 */
.MES4 {
background-color: var(--clr-17403);
&:hover {background-color: var(--clr-17404);}
color: #ffffff;
&:hover { color: var(--clr-17405);}
border-radius: 8px;
 }
/* Secondary:5 */
.MES5 {
background-color: var(--clr-17403);
color: #ffffff;
 }
.MES5 {
background-color: var(--clr-17403);
color: #ffffff;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: #ffffff;
 }
 }
cite.MEC5{
color: #ffffff;
}
/* Alternate:6 */
.MES6 {
background-color: var(--clr-17406);
 }
/* Alternate:7 */
.MES7 {
background-color: var(--clr-17406);
 }
/* Alternate:8 */
.MES8 {
background-color: var(--clr-17406);
 }
.MES8 {
background-color: var(--clr-17406);
 }
/* Alternate:9 */
.MES9 {
background-color: var(--clr-17406);
 }
.MES9 {
background-color: var(--clr-17406);
 }
/* Light:10 */
.MES10 {
background-color: var(--clr-17407);
 }
/* Light:11 */
.MES11 {
background-color: var(--clr-17407);
 }
.MES11 {
background-color: var(--clr-17407);
 }
/* Red Button:12 */
.MES12 {
background-color: var(--clr-17408);
&:hover {background-color: var(--clr-17401);}
color: var(--clr-17406);
&:hover { color: #ffffff;}
border-radius: 8px;
padding: 15px 10px;

 }
/* Shade 1:13 */
.MES13 {
background-color: var(--clr-17401);
color: #ffffff;
 }
.MES13 {
background-color: var(--clr-17401);
color: #ffffff;
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: #ffffff;
 }
 }
cite.MEC13{
color: #ffffff;
}
/* Copyright panel:14 */
.MES14 {
font-size: 12.8px;
 }
.MES14 {
font-size: 12.8px;
 }
cite.MEC14{
font-size: 12.8px;
}
/* News Grid:16 */
.MES16 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-17406);}
padding: 10px;

@media #{$medium-up} {
padding: 20px;

}
@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-17407);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES16 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-17406);}
padding: 10px;

@media #{$medium-up} {
padding: 20px;

}
@media #{$large-up} {
padding: 40px;

}
border-width: 1px;
border-style: solid;
border-color: var(--clr-17407);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Main Menu:17 */
nav.me-Menu.MES17 {
& .menu-item.MEC17, & .menu-item.MEC17 > div.MEC17{ & > a.MEC17{color: #ffffff;
text-transform: none;
}
 &:hover > a.MEC17{color: var(--clr-17409);
}
 }
&.horizontal > .menu-item.MEC17 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC17 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC17 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC17 .sub-menu { border:1px;
 }
& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Paypal button:18 */
.MES18 {
background-color: var(--clr-17410);
color: #ffffff;
 }
/* Home slider:19 */
.MES19 {
& .slider-arrow {color: var(--clr-17411);
font-size: 100px;
@media #{$medium-up} {
font-size: 100px;
};
@media #{$large-up} {
font-size: 80px;
};
}& .slick-dots {text-align: center;
 button {margin: 0 1px;
;border-color: 16;
border-radius: 50%;
border-style:solid;
border-width:2px;
@media #{$medium-up} {
border-width: 2px;
};
@media #{$large-up} {
border-width: 2px;};
&:hover{border-color: 11;}
background-color: 2;
background-clip: padding-box;
&:hover {background-color: 11;}
width:3px;
height:3px;
@media #{$medium-up} {
width:3px;
height:3px;
};
@media #{$large-up} {
width:3px;
height:3px;
};
&:hover{background-color: 11;}
}}
 }
/* Responsive Menu:20 */
nav.responsive-menu {
.menu-item.MEC20{background-color: var(--clr-17407);}
& .menu-item.MEC20, & .menu-item.MEC20 > div.menu-item-wrap{ & > a.MEC20, & > i{color: var(--clr-17400);
text-transform: uppercase;
text-align: center;
}
  }
& .menu-item.MEC20 { border:0;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 0;}

& .sub-menu{ .sub-menu {}}

 }
/* login buttons:21 */
.MES21 {
background-color: var(--clr-17407);
color: var(--clr-17400);
border-radius: 50px;
padding: 5px 15px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Copyright panel grey:22 */
.MES22 {
background-color: var(--clr-17407);
color: var(--clr-17409);
font-size: 12.8px;
 }
.MES22 {
background-color: var(--clr-17407);
color: var(--clr-17409);
font-size: 12.8px;
 }
a.MEC22 { color: var(--clr-17409);
&:hover { color: var(--clr-17400);}
 }
cite.MEC22{
color: var(--clr-17409);
font-size: 12.8px;
}
/* carousel padding:23 */
.MES23 {
padding: 20px 15px;

@media #{$large-up} {
padding: 40px;

}
 }
.MES23 {
padding: 20px 15px;

@media #{$large-up} {
padding: 40px;

}
 }
/* Carousel grey pad:24 */
.MES24 {
background-color: var(--clr-17406);
padding: 20px 15px;

@media #{$large-up} {
padding: 40px;

}
 }
.MES24 {
background-color: var(--clr-17406);
padding: 20px 15px;

@media #{$large-up} {
padding: 40px;

}
 }
/* border line:25 */
.MES25 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-17406) transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Top Strip Panel:26 */
.MES26 {
background-color: #ffffff;
color: var(--clr-17400);
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-17407) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES26 {
background-color: #ffffff;
color: var(--clr-17400);
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
padding: 5px;

border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent var(--clr-17407) transparent;
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
h1.MEC26, h2.MEC26, h3.MEC26, h4.MEC26, h5.MEC26, h6.MEC26 { color: var(--clr-17400);
 }
h1.MEC26 { @media #{$medium-up} { font-size: 26.4px; }; }
h1.MEC26 { @media #{$large-up} { font-size: 32px; }; }
h2.MEC26 { @media #{$medium-up} { font-size: 24px; }; }
h2.MEC26 { @media #{$large-up} { font-size: 30px; }; }
h3.MEC26 { @media #{$medium-up} { font-size: 20.8px; }; }
h3.MEC26 { @media #{$large-up} { font-size: 26px; }; }
h4.MEC26 { @media #{$medium-up} { font-size: 17.6px; }; }
h4.MEC26 { @media #{$large-up} { font-size: 22px; }; }
h5.MEC26 { @media #{$medium-up} { font-size: 14.4px; }; }
h5.MEC26 { @media #{$large-up} { font-size: 18px; }; }
h6.MEC26 { @media #{$medium-up} { font-size: 24px; }; }
h6.MEC26 { @media #{$large-up} { font-size: 50px; }; }
 }
a.MEC26 { color: var(--clr-17400);
&:hover { color: var(--clr-17409);}
 }
cite.MEC26{
color: var(--clr-17400);
font-size: 16px;
@media #{$large-up} {
font-size: 12.8px;
};
}
/* Slider White BG Trans:27 */
.MES27 {
background-color: var(--clr-17411);
padding: 20px;

 }
.MES27 {
background-color: var(--clr-17411);
padding: 20px;

h1.MEC27 { @media #{$medium-up} { font-size: 36.3px; }; }
h1.MEC27 { @media #{$large-up} { font-size: 48px; }; }
h2.MEC27 { @media #{$medium-up} { font-size: 33px; }; }
h2.MEC27 { @media #{$large-up} { font-size: 45px; }; }
h3.MEC27 { @media #{$medium-up} { font-size: 28.6px; }; }
h3.MEC27 { @media #{$large-up} { font-size: 39px; }; }
h4.MEC27 { @media #{$medium-up} { font-size: 24.2px; }; }
h4.MEC27 { @media #{$large-up} { font-size: 33px; }; }
h5.MEC27 { @media #{$medium-up} { font-size: 19.8px; }; }
h5.MEC27 { @media #{$large-up} { font-size: 27px; }; }
h6.MEC27 { @media #{$medium-up} { font-size: 33px; }; }
h6.MEC27 { @media #{$large-up} { font-size: 75px; }; }
 }
/* Popup form Window:28 */
.MES28 {
background-color: #ffffff;
padding: 20px;

border-width: 5px;
border-style: solid;
border-color: var(--clr-17407);
 }
.MES28 {
background-color: #ffffff;
padding: 20px;

border-width: 5px;
border-style: solid;
border-color: var(--clr-17407);
 }
/* Exhibition rollover:29 */
.MES29 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-17406);}
 }
.MES29 {
background-color: #ffffff;
&:hover, &.hover { background-color: var(--clr-17406);}
 }
/* Top Menu:30 */
nav.me-Menu.MES30 {
& .menu-item.MEC30, & .menu-item.MEC30 > div.MEC30{ & > a.MEC30{color: var(--clr-17400);
text-transform: none;
}
 &:hover > a.MEC30{color: var(--clr-17409);
}
 }
.menu-item.MEC30 { border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent #ffffff transparent;
 }
&.horizontal > .menu-item.MEC30 { border:0;
border-color: transparent;
border-style: none;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC30 { border:0;
border-color: transparent;
border-style: none;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC30 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC30 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 0 15px;}

& .sub-menu .menu-item a{padding: 15px;}



&.horizontal .menu-item.MEC30:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid var(--clr-17400-flat);}}
&.vertical .menu-item.MEC30:hover {& > .pointer-wrap > .pointer{border-right: 10px solid var(--clr-17400-flat);
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC30{background-color: var(--clr-17400); &:hover {background-color: 16;}
}
& .menu-item.MEC30, & .menu-item.MEC30 > div.MEC30{ & > a.MEC30{color: #ffffff;
}
 &:hover > a.MEC30{color: 2;
}
 }

}
}
 }
/* Header Panel - Grey line:31 */
.MES31 {
background-color: #ffffff;
border-width: 0 0 2px 0;
border-style: solid;
border-color: var(--clr-17407);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES31 {
background-color: #ffffff;
border-width: 0 0 2px 0;
border-style: solid;
border-color: var(--clr-17407);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Shade 2:32 */
.MES32 {
background-color: var(--clr-17402);
color: #ffffff;
&:hover { color: var(--clr-17411);}
 }
/* Shade 2:33 */
.MES33 {
background-color: var(--clr-17402);
color: #ffffff;
 }
.MES33 {
background-color: var(--clr-17402);
color: #ffffff;
h1.MEC33, h2.MEC33, h3.MEC33, h4.MEC33, h5.MEC33, h6.MEC33 { color: #ffffff;
 }
 }
cite.MEC33{
color: #ffffff;
}
/* Out of Stock:34 */
.MES34 {
color: var(--clr-17401);
 }
.MES34 {
color: var(--clr-17401);
h1.MEC34, h2.MEC34, h3.MEC34, h4.MEC34, h5.MEC34, h6.MEC34 { color: var(--clr-17401);
 }
 }
cite.MEC34{
color: var(--clr-17401);
}
/* Blank link :35 */
.MES35 {
color: var(--clr-17401);
&:hover { color: var(--clr-17409);}
 }
/* Line Border 1 px:36 */
.MES36 {
padding: 10px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-17407);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES36 {
padding: 10px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-17407);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* Panel White pad:37 */
.MES37 {
background-color: #ffffff;
padding: 20px 15px;

@media #{$large-up} {
padding: 40px;

}
 }
.MES37 {
background-color: #ffffff;
padding: 20px 15px;

@media #{$large-up} {
padding: 40px;

}
 }
/* Red panel padding:38 */
.MES38 {
background-color: var(--clr-17401);
color: #ffffff;
padding: 20px;

@media #{$large-up} {
padding: 40px;

}
 }
.MES38 {
background-color: var(--clr-17401);
color: #ffffff;
padding: 20px;

@media #{$large-up} {
padding: 40px;

}
h1.MEC38, h2.MEC38, h3.MEC38, h4.MEC38, h5.MEC38, h6.MEC38 { color: #ffffff;
 }
 }
cite.MEC38{
color: #ffffff;
}
/* Blue panel padding:40 */
.MES40 {
background-color: var(--clr-17402);
color: #ffffff;
padding: 20px;

@media #{$large-up} {
padding: 40px;

}
 }
.MES40 {
background-color: var(--clr-17402);
color: #ffffff;
padding: 20px;

@media #{$large-up} {
padding: 40px;

}
h1.MEC40, h2.MEC40, h3.MEC40, h4.MEC40, h5.MEC40, h6.MEC40 { color: #ffffff;
 }
 }
a.MEC40 { color: var(--clr-17401);
&:hover { color: var(--clr-17403);}
 }
cite.MEC40{
color: #ffffff;
}
/* Banner Panel Grey line:41 */
.MES41 {
border-width: 0 0 5px 0;
border-style: solid;
border-color: var(--clr-17406);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
.MES41 {
border-width: 0 0 5px 0;
border-style: solid;
border-color: var(--clr-17406);
border-top-style: solid;
border-right-style: solid;
border-bottom-style: solid;
border-left-style: solid;
 }
/* BUY Button:42 */
.MES42 {
background-color: transparent;
&:hover {background-color: var(--clr-17407);}
color: var(--clr-17400);
&:hover { color: var(--clr-17409);}
padding: 0 5px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-17400);
 }
/* :43 */
details.MES43 {
& > summary{background-color: var(--clr-17406);
}
& > summary{padding: 10px;}

& > article{padding: 15px;}

 }
/* Image BG Blue panel padding:44 */
.MES44 {
background-color: var(--clr-17402);
& > .underlay, & > .inner-overlay { opacity: 0.27 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://interactcard.com.au/img/2587/1080');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
padding: 20px;

@media #{$large-up} {
padding: 40px;

}
 }
.MES44 {
background-color: var(--clr-17402);
& > .underlay, & > .inner-overlay { opacity: 0.27 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://interactcard.com.au/img/2587/1080');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
padding: 20px;

@media #{$large-up} {
padding: 40px;

}
h1.MEC44, h2.MEC44, h3.MEC44, h4.MEC44, h5.MEC44, h6.MEC44 { color: #ffffff;
 }
 }
a.MEC44 { color: var(--clr-17401);
&:hover { color: var(--clr-17403);}
 }
cite.MEC44{
color: #ffffff;
}
/* Panel Popup Search:46 */
.MES46 {
background-color: var(--clr-17411);
 }
.MES46 {
background-color: var(--clr-17411);
 }
/* Edikio Btn:47 */
.MES47 {
background-color: var(--clr-17400);
&:hover {background-color: var(--clr-17412);}
color: #ffffff;
&:hover { color: var(--clr-17407);}
border-radius: 8px;
border-width: 2px;
border-style: solid;
border-color: #ffffff;
&:hover { border-color: var(--clr-17406); }
 }
/* Eco Background:48 */
.MES48 {
background-color: var(--clr-17402);
& > .underlay, & > .inner-overlay { opacity: 0.15 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://interactcard.com.au/img/6440/1199');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
 }
.MES48 {
background-color: var(--clr-17402);
& > .underlay, & > .inner-overlay { opacity: 0.15 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://interactcard.com.au/img/6440/1199');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: #ffffff;
 }
cite.MEC48{
color: #ffffff;
}
/* Dark Grey:49 */
.MES49 {
background-color: var(--clr-17409);
 }
.MES49 {
background-color: var(--clr-17409);
 }
/* Black Border Test:50 */
.MES50 {
background-color: #ffffff;border-radius: 8px;
border-width: 3px;
border-style: solid;
border-color: #ffffff;
 }
/* Cardpresso Btn:51 */
.MES51 {
background-color: #ffffff;
&:hover {background-color: var(--clr-17412);}
color: var(--clr-17400);
&:hover { color: #ffffff;}
border-radius: 8px;
border-width: 2px;
border-style: solid;
border-color: var(--clr-17400);
&:hover { border-color: var(--clr-17404); }
 }
/* Blue Btn:52 */
.MES52 {
background-color: var(--clr-17402);
color: #ffffff;
&:hover { color: var(--clr-17411);}
 }
/* Dark Blue Btn:53 */
.MES53 {
background-color: var(--clr-17402);
&:hover {background-color: var(--clr-17413);}
color: #ffffff;
&:hover { color: var(--clr-17407);}
border-radius: 8px;
border-width: 2px;
border-style: solid;
border-color: #ffffff;
&:hover { border-color: var(--clr-17406); }
 }
/* News list rollover:54 */
.MES54 {
background-color: var(--clr-17400);
&:hover, &.hover { background-color: var(--clr-17412);}
color: #ffffff;
border-radius: 8px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 2px;
border-style: solid;
border-color: transparent;
&:hover { border-color: var(--clr-17411); }
 }
.MES54 {
background-color: var(--clr-17400);
&:hover, &.hover { background-color: var(--clr-17412);}
color: #ffffff;
border-radius: 8px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 2px;
border-style: solid;
border-color: transparent;
&:hover { border-color: var(--clr-17411); }
 }
cite.MEC54{
color: #ffffff;
}
/* Page Accordion:55 */
details.MES55 {
 }
/* Fine Food 2024:56 */
.MES56 {
background-color: #d1f7d3;
 }
.MES56 {
background-color: #d1f7d3;
 }
